import { render, staticRenderFns } from "./AdminForgotLogin.vue?vue&type=template&id=42a55793&scoped=true&"
import script from "./AdminForgotLogin.vue?vue&type=script&lang=js&"
export * from "./AdminForgotLogin.vue?vue&type=script&lang=js&"
import style0 from "./AdminForgotLogin.vue?vue&type=style&index=0&id=42a55793&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "42a55793",
  null
  
)

export default component.exports